.about-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-container .content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.about-container .content .left{
    width: 45%;
}

.about-container .content .right{
    width: 45%;
}

.about-container h1{
    border-bottom: 3px solid var(--accent-color);
    font-weight: 400;
}

.about-container .content h4{
    font-size: 1.1rem;
}

.about-container .content a{
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 1rem;
}

.about-container span h2{
    border-bottom: 3px solid var(--accent-color);
    font-size: 2.25rem;
    font-weight: 400;
}

.team{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.team > .card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}