.browse-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.browse-page .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    margin-top: 1rem;
}
.browse-page .content .mainText{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.browse-page .content .mainText input[type="checkbox"]{
    aspect-ratio: 1/1;
    margin-left: 0.5rem;
}
.browse-page .form-browse{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.browse-page input[type="text"] {
    width: calc(90% - 20px);
    padding: 20px 20px;
    margin: 1rem 0;
    box-sizing: border-box;
    border: none !important;
    font-size: 1.5rem;
    outline: none;
    color: var(--content-color);
    background-color: var(--accent-background);
    border-radius: var(--border-radius);
}
