.new-question-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
}
.new-question-container h1 {
    font-size: 3rem;
    margin: 0.5rem 0;
    font-weight: 500;
}
.new-question-container h2 {
    font-size: 1.75rem;
    margin: 0.5rem 0;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 2rem;
}

.new-question-container textarea {
    font-size: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--accent-background);
    border-bottom: 3px solid var(--accent-background) !important;
    /* box-shadow: var(--box-shadow); */
    transition: all 0.25s;
    border: 0px;
    outline: 0px;
    padding: 1rem;
    margin-top: 1rem;
}
.new-question-container textarea:focus {
    border-bottom: 3px solid var(--accent-color) !important;
    border-radius: var(--border-radius) 0rem;
}
.tag-input-container {
    box-shadow: var(--box-shadow);
    border-radius: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}
.tag-input-container input {
    background-color: var(--accent-background);
    padding: 1.15rem;
    border-radius: 10rem;
    outline: none;
    border: none;
    font-size: 0.9rem;
}
.each-tag-entry {
    /* min-width: 10%; */
    border-radius: 2rem;
    border: 2px solid var(--shadow-color);
    box-shadow: var(--box-shadow);
    margin-right: 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.each-tag-entry p {
    padding: 1rem;
    font-size: 0.9rem;
    margin: 0;
}
.each-tag-entry button {
    background-color: transparent;
    border: 0;
    fill: var(--shadow-color);
    cursor: pointer;
    transition: all 0.25s;
    height: 2.9rem;
    /* width: 2.9rem; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.each-tag-entry button:hover {
    transform: rotate(90deg);
}
.fade-in-alert {
    animation: comeIn 2s;
    height: 0px;
    opacity: 0;
}
@keyframes comeIn {
    0% {
        opacity: 0;
        height: 0px;
    }
    10% {
        opacity: 1;
        height: 2.5rem;
    }
    90% {
        opacity: 1;
        height: 2.5rem;
    }
    100% {
        opacity: 0;
        height: 0px;
    }
}

.btn-wrapper {
    display: flex;
    gap: 16px;
    margin-top: 2rem;
}