.google-button {
    width: auto;
    height: 5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: var(--accent-background);
    border-radius: 1rem;
    border: none;
    box-shadow: var(--box-shadow);
    margin-top: 2rem;
    transition: transform 0.25s;
    cursor: pointer;
}
.google-button:hover {
    transform: scale(1.02);
    box-shadow: 0 0 0.6em -0.1em var(--shadow-color);
}

.google-button:active {
    transform: scale(0.98);
}
.login-total-container h2 {
    font-weight: 400;
}
.login-total-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.login-total-container div {
    min-width: 400px;
    max-width: 50%;
    padding: 2rem;
}
.login-total-container span {
    color: var(--accent-color);
}
.login-total-container h2 {
    margin: 0px;
    font-size: 2.5rem;
}

.logout-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logout-container div {
    max-width: 50%;
    min-width: 600px;
}
.logout-container h1 {
    font-size: 3rem;
    margin-right: 1rem;
}

button p {
    color: var(--content-color);
}
