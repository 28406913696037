.your-questions-title {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}
.your-questions-title h1 {
    width: auto;
    margin: 0px;
    margin-right: 1rem;
    font-size: 3rem;
}
.individual-question-div {
    box-shadow: 0 0 0.5em -0.2em var(--shadow-color);
    padding: 1rem;
    width: calc(100% - 2rem);
    border-radius: 1rem;
    margin-bottom: 2rem;
}
.individual-question-div a {
    text-decoration: underline;
    color: var(--content-color);
}
.individual-question-div h2 {
    margin: 0px;
    margin: 0.25rem 0rem;
    font-weight: 500;
}
.all-questions-container {
    height: calc(100% - 4rem);
    width: 100%;
    display: flex;
    flex-direction: column;
}
.your-questions-container {
    width: calc(60% - 2rem);
    margin-right: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}
.suggested-actions-container {
    width: 30%;
    margin-right: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.entire-dashboard {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 8rem);
}
.suggested-questions-container {
    box-shadow: 0 0 0.5em -0.2em var(--shadow-color);
    border-radius: 1rem;
    padding: 1rem;
}
.each-suggested-question {
    background-color: var(--accent-background);
    width: calc(100% - 1rem);
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.5rem;
}
.each-suggested-question p {
    margin: 0.5rem 0rem;
    margin-left: 0.5rem;
    margin-top: 0.75rem;
    font-size: 1.25rem;
}

.each-suggested-question-tag {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    padding-bottom: 0.25rem;
}
.reply-each-question {
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: right;
    color: var(--content-color);
    width: calc(100% - 0.5rem);
    padding: 0.25rem;
    margin-right: 0.25rem;
    margin-top: 0.25rem;
}
/* Make a custom scrollbar with accent-color as the color of the thumb, a transparent background, and a 1px border for the each-suggested-question-tag class */
.each-suggested-question-tag::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.5rem;
    margin: 0.25rem;
    border-radius: 0.25rem;

    background-color: var(--background-color);
}
.each-suggested-question-tag::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
    border-radius: 0.25rem;
}
.each-suggested-question-tag::-webkit-scrollbar-track {
    background-color: transparent;
}
.button-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0.75rem;
}

.deleteButton{
    background-color: transparent;
    color: var(--reported);
    border: none;
    cursor: pointer;    
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.topPart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.action-button-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.action-button-group span {
    display: flex;
    align-items: center;
    margin: 0 0.5rem;
    background-color: var(--accent-background);
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.25s;
}
.action-button-group span:hover {
    box-shadow: var(--box-shadow);
    transform: scale(1.05);
}
.action-button-group span:active {
    transform: scale(0.95);
}
.action-button-group h2 {
    width: 80%;
}
.action-button-group span > p {
    margin: 0px;
    margin-right: 0.5rem;
}

.bannedPageContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.ask-more-questions {
    position: fixed !important;
    left: 1rem;
    bottom: 1rem;
    z-index: 5;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    flex-wrap: wrap;
    gap: 8px;
}
.no-questions-wrapper {
    font-size: 25px;
    font-weight: 300;
    margin-top: 12px;
}