.card{
    background-color: whitesmoke;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    border-radius: 0.5rem;
    word-wrap: break-word;
    background-clip: border-box;
    min-width: 200px;
    max-width: 200px;
    margin: 1vh 1vw;
}

.card img{
    object-fit: cover;
}

.card:hover{
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}



.card .links{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.links > *{
    padding: 0.5rem 0;
    border-radius: 16px;
    color: black;
}

.card-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

