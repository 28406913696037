.view-replies-container {
    width: calc(100% - 8rem);
    margin-left: 4rem;
}
.view-replies-container h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    display: block;
}
.each-reply {
    margin: 1vh 15px;
    padding: 1rem;
    width: calc(100% - 2rem - 30px);
    border-radius: 1rem;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.each-reply div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.each-reply span {
    display: flex;
    align-items: center;
    margin: 0 0.5rem;
    background-color: var(--accent-background);
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.25s;
}
.each-reply span:hover {
    box-shadow: var(--box-shadow);
    transform: scale(1.05);
}
.each-reply span:active {
    transform: scale(0.95);
}
.each-reply h2 {
    width: 80%;
}
.each-reply span > p {
    margin: 0px;
    margin-right: 0.5rem;
}
.replies-entry {
    width: calc(100% - 5rem);
    background-color: var(--accent-background);
    border-radius: 1rem;
    box-shadow: var(--box-shadow);
    padding: 0.5rem;

    height: auto;
    position: fixed;
    top: calc(100vh - 10rem);
    left: 2rem;
}
.replies-entry textarea{
    width: calc(100% - 2rem);
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    margin: 1rem;
    resize: none;
}
.replies-entry div {
    display: flex;
    justify-content: flex-end;
}

.replies-scroll{
    width: calc(100%);
    height: 60vh;
    overflow-x: hidden;
}