.ind-rating-container{
    padding: 1rem;
    background-color: var(--accent-background);
    box-shadow: var(--box-shadow);
    border-radius: 1rem;
    width: 10vw;
    height: 10vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
    transition: all 0.25s;
}
.leftSide p{
    cursor: pointer;
}
.ind-rating-container:hover {
    transform: scale(1.025);
}
.ind-rating-container:active {
    transform: scale(0.975);
}
.ind-rating-container p {
    margin: 0px;
    font-size: 1.35rem;  
}
.ind-rating-container h2 {
    font-size: 3rem;
    margin: 0px;
}
.ind-rating-container span {
    font-size: 1rem;
}
.overall-rating-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.classContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.classContainer .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 1.25vw;
}

.classContainer h3 {
    font-weight: normal;
}

.inputRating h3{
    font-size: 1.75rem;
}

.classContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.classContent h1{
    font-size: 2.25rem;
}

.classContent > * {
    margin: 0 3vw;
}

.leftSide {
    display: flex;
    flex-direction: column;
    align-self: start;
    width: 50vw;
}

.rightSide {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: calc(35vw + 10px);
}

.reviews {
    display: flex;
    flex-direction: column;
    align-self: start;
    width: 35vw;
    overflow-y: scroll;
    overflow-x: hidden;
}

.reviews::-webkit-scrollbar {
    display: none;
}
.all-buttons-individual-rating {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.all-buttons-individual-rating .action-button-group span{
    border: 0.5px solid black;
}

.reviews::-webkit-scrollbar {
    width: 10px;
    border-radius: var(--border-radius);
    background-color: var(--accent-background);
}

.reviews::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
}

.noReviewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.sliders {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5vh;
}

.sliders h3 {
    font-weight: 500;
}

.noReviewContainer h1 {
    margin: 10vh 0 0 0vh;
    color: var(--accent-color);
}

.noReviewContainer a {
    color: black;
    margin: 0;
    transition: all 0.3s ease-in;
}
.noReviewContainer a:hover {
    color: var(--accent-color);
}

.box {
    margin: 2vh 0;
    padding: 0.5vh 1vw;
    transition: all 0.1s ease-in;
    background-color: var(--accent-background);
    border-radius: var(--border-radius);
}

.box .openClickPart{
    width: 95%;
}
.box h4:hover {
    cursor: pointer;
}

.boxButtons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1vw;
    margin-bottom: 0.5rem;
}
.box h4{
    font-size: 1.5rem;
}

.reviewStar {
    font-size: "3em";
}

.boxButtons button {
    border-radius: 1.5rem;
    cursor: pointer;
    font-size: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
}
.boxButtons button > svg {
    margin-right: 0.25rem;
}

.modalContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    outline: none;
    width: fit-content;
    margin: auto;
    /* margin-top: 25%; */
    /* height: 50vh; */
    /* centers it actually */
    z-index: 2;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.modalContent {
    background-color: var(--background-color);
    width: 75vw;
    /* height: 50vh; */
    border-radius: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    pointer-events: all;
}

.modalLeft {
    margin: 0 3vw;
    width: 60%;
}
.modalRight {
    width: 40%;
}
.ranking-in-modal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
}
.ranking-in-modal > .ind-rating-container {
    width: calc(45% - 4rem);
    height: 6rem;
}
.modaltitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    font-size: 2rem;
}


.modaltitle h2 {
    font-size: 1.5em;
    margin: 0.5vh 0;
}

.modalContent h3 {
    font-size: 1.25em;
    margin: 1vh 0;
    font-weight: normal;
}
.modalContent h4 {
    font-size: 1.5em;
    margin: 1vh 0;
}

.modalLeft p {
    font-size: 1rem;
    margin: 2vh 0;
}

.boxButton{
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 3px solid var(--accent-color) !important;
    background-color: transparent;
}

