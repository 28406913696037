.navbar {
    overflow: hidden;
    width: 100%;
    max-width: 100%; /* added */

    min-height: 5rem;
    height: 6rem;
    padding: 1rem;
    box-sizing: border-box;
}

.navbarContent {
    background-color: var(--accent-background);
    height: 100%;
    border-radius: 3.5rem;
    box-shadow: 0 0 0.5em -0.2em var(--shadow-color);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--shadow-color);
}

.navbarLogo {
    margin-left: 1rem;
    height: 100%;
}

.searchBar {
    flex: 1;
    background-color: var(--accent-background);
}

.searchInput {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem;
    box-sizing: border-box;
    font-size: large;
    background-color: var(--accent-background);
}

.navbarOption {
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    transition: background-color 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbarOption:hover {
    background-color: var(--accent-color);
    color: black; /*Accent color independent from dark/light mode as of right now*/
}

.navbar a {
    text-decoration: none !important;
    font-size: large;
    color: var(--content-color);
}

.navbarPhoto {
    height: 100%;
    aspect-ratio: 1;
    padding: 0.7rem;
    box-sizing: border-box;
}

.navbarPhotoImage {
    height: 100%;
    border-radius: 100%;
    background-color: gray;
    padding: 0rem;
    box-sizing: border-box;
}

.nav-right{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}