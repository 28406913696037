.forumContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.forumContainer .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 3vw;
}

.tagFilter input{
    margin: 2vh 1vh !important;
    box-sizing: border-box;
    border: none !important;
    width: calc(95% - 20px);
    padding: 20px;
    font-size: 1.5rem;
    outline: none;
    color: var(--content-color);
    background-color: var(--accent-background);
    border-radius: var(--border-radius);
}

.askMore{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}
.askMoreLeft{
    position: fixed;
    bottom: 1rem;
    left: 1rem;
}

.questionContainer{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 1rem 0;
}
