.pageContent {
    box-sizing: border-box;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: var(--background-color);
}
.titlesforReview {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--text-color);
    margin: 0;
    margin-top: 1rem;
}

.horizontalReviewDiv {
    display: flex;
    flex-wrap: wrap;
}

.reviewSliderDiv {
    padding-left: 1.5rem;
    max-height: 45vh;
}

#enterReview > input[type="text"] {
    width: calc(100% - 20px);
    padding: 20px 20px;
    margin-top: 1rem;
    box-sizing: border-box;
    border: none !important;
    font-size: 1.5rem;
    outline: none;
    color: var(--content-color);
    background-color: var(--accent-background);
    border-radius: var(--border-radius);
}

.inputRating {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inputRating > * {
    margin-right: 1rem !important;
}

.inputRating h2 {
    font-size: 3rem;
    font-weight: bold;
    margin: 2vh 0;
    color: var(--text-color);
}

.horizontalReviewDiv textarea {
    font-family: inherit;
    width: 60vw;
    height: 50vh;
    padding: 20px 20px;
    box-sizing: border-box;
    border: none !important;
    font-size: 1rem;
    outline: none;
    color: var(--content-color);
    background-color: var(--accent-background);
    border-radius: var(--border-radius);
    font-size: 1.5rem;
    resize: none;
    margin-bottom: 1rem;
}

h1 {
    margin: 0;
}

/* .reviewSliderDiv input[type="text"] {
    width: auto;
    padding: 20px 20px;
    box-sizing: border-box;
    border: none !important;
    font-size: 1.5rem;
    outline: none;
    color: var(--content-color);
    background-color: var(--accent-background);
    border-radius: var(--border-radius);
    text-transform: none;

} */

.reviewSubmit {
    width: 7rem;
    border: none;
    box-sizing: border-box;
    font-size: 1rem;
    padding: 20px 0;
    color: var(--content-color);
    background-color: var(--accent-color);
    transition: all 0.4s ease-out;
    border-radius: var(--border-radius);
}
.reviewSubmit:hover {
    background-color: var(--content-color); /*fix button color + position */
    color: var(--accent-background);
}
.year-input {
    width: 4rem;
    border: none !important;
    margin-top: 13px;
    font-size: 1rem;
    padding: 1rem;
    outline: none;
    color: var(--content-color);
    background-color: var(--accent-background);
    border-radius: var(--border-radius);
    text-transform: uppercase;
}
