
html {
    --background-color: white;
    --accent-background: #f5f5f5;
    --content-color: black;
    --shadow-color: gray;
    --box-shadow: 0 0 0.5em -0.2em var(--shadow-color);
    scroll-behavior: smooth;
    --accent-color: rgb(242, 199, 59);
    --transparent-accent-color: rgba(242, 199, 59, 0.75);
    --liked: green;
    --reported: red;
    
    --border-radius: 0.5em;
    margin: 0%;

    background-color: var(--background-color);
}
a {
    text-decoration: none;
}
h1 {
    font-size: 3rem;
}
h4 {
    font-weight: normal;
}
.flexbox{
    display: flex;
}
.column{
    flex-direction: column;
}
.row{
    flex-direction: row;
}
.center{
    justify-content: center;
    align-items: center;
}

.App, .content {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

body {
    background-color: var(--background-color);
    margin: 0;
    font-family: "Roboto", sans-serif;
    color: var(--content-color);
    text-decoration: none;
}
textarea {
    font-family: "Roboto", sans-serif;
}
.mobileProblem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    width: 100vw;
    height: 100vh;
}

.mobileProblem p{
    margin: 0.25rem 0;
}

.mobileProblem h1{
    font-size: 3rem;
    color: var(--content-color);
}

.notFound{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
}
